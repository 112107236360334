/* eslint-disable */
import {lista, processar, autentica, pegaLista, setarToken, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {UsuarioSCH} from "../../search/UsuarioSCH"

import {EventBus} from '../../helpers/event-bus'
import _ from 'lodash'

const url = '/usuario'

const state = {
  all: [],
  filtro: new Filtro()
}

const getters = {
  listaUsuarios: (state) => {
    return state.all
  },
  listaUsuariosSemFiltro: (state) => {
    return state.all
  },
  pegaUsuarioId: (state) => (id) => {
    let mun = state.all.find(usuario => usuario.user_id === id)
    return mun !== null ? mun.descricao : '** NÃO ENCONTRADO'
  },

  pegaUsuario: (state) => (id) => {
    let mun = state.all.find(usuario => usuario.user_id === id)
    return mun !== null ? mun : '** NÃO ENCONTRADO'
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.addSearch(['nome', 'email'],
       '%'+search+'%',
        'ilike'
      )
  },
  async getItem({commit}, id) {

    //  var filtro = getBaseSCH('participante_id', id, '=')
    //  const list = (await pegaLista(url, filtro)).data
    //   commit('SET_USUARIO', list.data[0])
    //  EventBus.$emit('GET_CONCLUIDO', true)

  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', UsuarioSCH(perPage))
  },
  async setAllUsuarios({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      // list.data.forEach(function (l) {
      //   l.password = ''
      //   l.password_conf = ''
      // })
      // console.log('SET_USUARIOS: ', list.data)
      commit('SET_USUARIOS', list)
      // EventBus.$emit('LISTA_PROCESSADA', true)
      onSucesso()
    } catch (error) {
      // EventBus.$emit('LISTA_PROCESSADA', false)
      onErro()
    }
  },
  async processarUsuario({commit}, {usuario, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , usuario, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      console.log(error.response);
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  async autenticarUsuario({commit}, {usuario, onSucesso, onErro}) {
    try {
      console.log('autenticarUsuario');
      let res = await autentica(url + '/auth', usuario)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      // EventBus.$emit('USUARIO_AUTENTICADO', true)
      onSucesso()
    } catch (error) {
      console.log(error);
      this.dispatch('geral/setObjMsgError', error.response.data)
      // EventBus.$emit('USUARIO_AUTENTICADO', false)
      onErro(error)
    }
  }
}

const mutations = {
  SET_USUARIOS(state, usuarios) {
    state.all = usuarios
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
