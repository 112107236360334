/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import store from './store/index'
import VueCropper from 'vue-cropperjs';
import VueEditor from 'vue2-editor'
import VuejsDialog from 'vuejs-dialog'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import './assets/css/main.scss'
import 'font-awesome/css/font-awesome.css'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import 'font-awesome/css/font-awesome.css'
import 'cropperjs/dist/cropper.css'

import PForm from './components/PForm.vue'
import CropperImagem from './components/CropperImagem.vue'
import { sync } from 'vuex-router-sync'
import ModalCadastro from './components/ModalCadastro.vue'
import ProCard from './components/ProCard.vue'
import TabelaSimples from './components/TabelaSimples.vue'

import ProAlerta from './components/ProAlerta.vue'

import TableIterator from './components/TableIterator.vue'
import currency from 'v-currency-field'
import IsBlocked from "./components/IsBlocked";

Vue.use(currency)
Vue.component('p-form', PForm)
Vue.component('table-simples', TabelaSimples)
Vue.component('table-iterator', TableIterator)
Vue.component('vue-editor', VueEditor)
Vue.component('vue-cropper', VueCropper)
Vue.component('cropper-imagem', CropperImagem)
Vue.component('pro-card', ProCard)
Vue.component('pro-modal-cadastro', ModalCadastro)
Vue.component('pro-alerta', ProAlerta)
Vue.component('is-blocked', IsBlocked)


Vue.use(Vuetify, { theme: {
    primary: '#1d94ff',
    secondary: '#0a593b',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  } })


Vue.use(VuejsDialog, {
  html: true,
  loader: true,
  okText: 'Continuar',
  cancelText: 'Cancelar',
  animation: 'fadeIn',
  type: 'alert'
})



Vue.config.productionTip = false

sync(store, router)

const vuetifyOptions = {
  icons: {
    iconfont: 'mdi'
  }
}

new Vue({
  router,
  store,
  render: h => h(App),
  vuetify: new Vuetify(vuetifyOptions)
}).$mount('#app')

