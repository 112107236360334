/* eslint-disable */
import {lista, processar, pegaLista, pegaDados, nao_autorizado} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {ApuracaoSCH, getBaseSCH} from "../../search/ApuracaoSCH"
import {EventBus} from '../../helpers/event-bus'
import Apuracao from '../../models/apuracao'

const url = '/apuracao';

const state = {
  all: [],
  apuracao: new Apuracao(),
  filtro: new Filtro()
};
const getters = {
  listaApuracaos: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaApuracao: (state) => {
    return state.apuracao !== undefined ? state.apuracao : new Apuracao()
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setApuracao({commit}, apuracao) {
    commit('SET_APURACAO', apuracao)
  },
  searchItem({commit}, search) {
    state.filtro.filters.addFilter('titulo' ,
      "%"+search+"%",
      'ilike'
    )
  },
  async getItem({commit}, id) {
    var filtro = getBaseSCH('apuracao_id', id, '=');
    const list = (await pegaLista(url, filtro)).data;
    commit('SET_APURACAOS', list.data[0]);
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ApuracaoSCH(perPage))
  },

  async setAllApuracaos({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_APURACAO', list.data[0]);
      onSucesso()
    } catch (error) {
      onErro()
      console.log(error.response.data);
      // this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },
  async processarApuracao({commit}, {apuracao,acao,onSucesso, onErro}) {
    try {
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , apuracao, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },

};

const mutations = {
  SET_APURACAOS(state, apuracaos) {
    state.all = apuracaos
  },
  SET_APURACAO(state, apuracao) {
    state.apuracao = apuracao
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

