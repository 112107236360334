/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import geral from './modules/geral'
import usuario from './modules/usuarios'
import cartao from './modules/cartoes'
import apuracao from './modules/apuracao'


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    geral,
    usuario,
    cartao,
    apuracao,
  }
})
