<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-data-table fixed-header :headers="cabecalho" :items="itens"  class="elevation-1 tabela_simples"
                  hide-default-footer
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :items-per-page="select">

      <template v-slot:[n.slot] ="{ item }" v-for="(n, index) in cabecalho" :id="index">
         <slot :name="n.slot_name"  v-bind:item="item"></slot>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="acao_tabela">
          <slot name="acao" v-bind:item="item"></slot>
        </div>
      </template>

      <template v-slot:no-data>
        <div style="text-align: center;padding: 100px">
          <span style="font-weight: normal">Nada há exibir no momento</span>
        </div>
      </template>
    </v-data-table>
    <v-container max-width="300">
      <div>
        <v-row style="padding: 12px">
          <v-divider style="margin: 0"></v-divider>
        </v-row>
        <v-row dense style="align-items: flex-end;padding: 5px" v-if="showFooter">
          <div>
            <v-text-field
              :value="select"
              type="number"
              label=""
              hint="Itens por página" persistent-hint min="1"
              @input="select = parseInt($event, 10)"
            ></v-text-field>
          </div>
          <v-spacer></v-spacer>
          <v-pagination  v-model="page" :length="getTotalPagina" :total-visible="7"/>

          <v-spacer></v-spacer>
          <v-btn v-if="showAddButton" tile color="primary" @click="goToAdd" :disabled="disabledAddButton">
            <v-icon>add</v-icon>
            NOVO
          </v-btn>
          <slot name="slot_footer"></slot>
        </v-row>
      </div>
    </v-container>
  </div>

</template>

<script>
/* eslint-disable */
  import {EventBus} from "@/helpers/event-bus";
import {mapGetters} from "vuex";

  export default {
    name: "ProTableSimples",
    props: {
      itens: {type: Array, default: () => []},
      cabecalho: {type: Array, default: () => []},
      dense: {type: Boolean, default: false},
      itemsPerPage: {default: 10},
      tabela: {type: String, default: ''},
      showAddButton: {type: Boolean, default: true},
      showFooter: {type: Boolean, default: true},
      disabledAddButton: {type: Boolean, default: false},
    },
    data: () => ({
      select: 10,
      page: 1,
      pageCount: 1,
      hide_add: false,
    }),
    methods: {
      goToAdd() {
        this.$emit('novo')
      },
      lista(item) {
        this.$emit('item', item)
      },
    },
    computed: {
      getTotalPagina() {
        return Math.ceil(this.itens.length / this.select)
      },
      ...mapGetters('geral', {search: 'getSearch'})
    },
    watch: {
      select: function () {
        this.select = parseInt(this.select) <= 0 ? 1 : this.select
        this.page = 1
      },
      setPagina: function () {
        if (this.setPagina) {
          this.page = 1
        }
      },
      page: function () {
        this.page = isNaN(this.page) ? 0 : this.page
      },
    },
  }
</script>
