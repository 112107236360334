<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-navigation-drawer width="260px" left class="gradiente" id="sidebar" ref="sideDrawer" stateless value="true" dark
                         persistent :mini-variant="miniVariant" :clipped="clipped"
                         enable-resize-watchers app>
      <v-list dense>
        <v-list-item>
          <v-list-item-title class="title">{{$route.meta}}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-list>
        <v-list-item  to="principal">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-group prepend-icon="list" no-action style="text-align: left" dense>

          <template v-slot:activator>
            <v-list-item style="text-align: left">
              <v-list-item-title>Cadastros</v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item  @click="irPara(cadastro.path)"
                       v-for="(cadastro, index) in cadastros" :key="index">
            <v-list-item-content>
              <v-list-item-title :id="cadastro.path">{{ cadastro.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>
        <v-list-item  @click="sair()">
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main fluid id="conteudo">
      <div style="position: relative !important;">
        <router-view/>
        <br>
        <!--<content-footer></content-footer>-->
      </div>
    </v-main>

    <!-- Dialog que vai exibir a lista de erros caso aja uma validação -->
    <v-dialog v-model="dialogError" max-width="500">
      <v-card v-if="objMsgError !== null">
        <v-card-title class="headline">{{ objMsgError.message }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-if="objMsgError.details !== null">
            <ul :key="index" v-for="(message, index) in objMsgError.details">
              <li> {{ message.mensagem }}</li>
            </ul>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="toggleDialogError()">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Popup que vai exibir mensagens de sucesso -->
    <v-snackbar v-model="showSnack" top color="success" :timeout="2000">
      <div class="d-flex justify-space-between align-center">
        {{ msgSuccess }}
        <v-btn small color="white" text @click="toggleSnackSuccess">
          <v-icon>close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
  import {EventBus} from '../helpers/event-bus'
  import router from '../router'
  import {mapState, mapActions, mapGetters} from 'vuex'
  import miscMixin from "../helpers/misc";

  export default {
    mixins: [miscMixin],
    components: {
    },
    data() {
      return {
        selectedItem: null,
        pesquisa: null,
        show_progress: false,
        search: '',
        clipped: false,
        title: 'Titulo',
        // principal: [{path: 'admin/principal', title: 'Principal', icon: 'home'}],
        cadastros: [
          {path: 'cartao', title: 'Cartao', icon: 'mdi-calendar'},
          {path: 'apuracao', title: 'Apuracao', icon: 'mdi-calendar'},
          {path: 'usuario', title: 'Usuario', icon: 'fa fa-user'},

        ],
      }
    },
    name: 'Dash',
    methods: {
      ...mapActions('geral', ['setMiniVariant', 'setShowFilter', 'toggleMiniVariant', 'setSidebarColor',
        'setSidebarID', 'setModalFiltro','setRenovarLogin',
        'setModal', 'toggleModalFiltro', 'setSearch', 'toggleDialogError', 'toggleSnackSuccess']),
      sair() {
        sessionStorage.clear()
        router.push({path: '/'}).catch(err => {
        })
      },
      irPara(path) {
        router.push({path: '/admin/'+  path}).catch(err => {
        })
      },
      cadastrar() {
        this.setModal(true)
      },
      abreFechaSidebar() {
        this.toggleMiniVariant()
      },
    },
    watch: {
    },
    mounted() {
    },
    computed: {
      ...mapState('geral', {
        drawer: 'drawer',
        toolbarsave: 'toolbarsave',
        toolbarsearch: 'toolbarsearch',
        btnAdd: 'btnAdd',
        miniVariant: 'miniVariant',
        modalFiltro: 'modalFiltro',
        toolbarDash: 'toolbarDash',
        dialogError: 'dialogError',
        objMsgError: 'objMsgError',
        snackSuccess: 'snackSuccess',
        showFilter: 'showFilter',
        msgSuccess: 'msgSuccess'
      }),
      showSnack: {
        get() {
          return this.snackSuccess
        },
        set() {
          this.toggleSnackSuccess()
        }
      }
    },
    beforeDestroy() {
    }
  }
</script>

<style lang="scss">

</style>
