/* eslint-disable */
import router from '../router'
import {API,setToken} from '../api'
import {EventBus} from '../helpers/event-bus'
import {acaoApi} from '../helpers/enums'

export const lista = (url) => {
  return API.get(url)
};

export const processar = (url, objeto, acao) => {
  // setToken(sessionStorage.getItem('token'))
  if (acao === acaoApi.GRAVAR) {
    var res = API.post(url, objeto)
  } else if (acao === acaoApi.REMOVER) {
    var res = API.delete(url, {data: objeto})
  }

  // res.catch(
  //   (erro) => {
  //     if(erro.response.status === 401){
  //       // var renovar = (erro.response.data.message === "Token has expired")
  //       // EventBus.$emit('RENOVAR_LOGIN', renovar)
  //     }
  //   }
  // )
  return res

};
export const pegaLista = (url, data) => {
  setToken(sessionStorage.getItem('token'))
  return API.get(url, {params: {data: data}})
};
export const pega = (url, id) => {
  // setToken(sessionStorage.getItem('token'))
  return API.get(url + '/' + id)
};
export const autentica = (url, objeto) => {
  // setToken(sessionStorage.getItem('token'))
  return API.post(url, objeto)
};

export const pegaDados = (url, data) => {
  // setToken(sessionStorage.getItem('token'))
  var res = API.post(url, data)
  return res
};

export const setarToken = (token) => {
  setToken(token)
}

export const nao_autorizado = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      sessionStorage.clear()
      router.push({path: '/'}).catch(err => {
      })
    }
  }
}


