/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from './views/Dash.vue'
import Login from './views/Login.vue'

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Login,
    },
    {
      path: '/admin',
      name: 'admin',
      component: Dash,
      meta: 'Admin',
      children: [
        // PRINCIPAL
        {
          path: 'principal',
          name: 'Principal',
          meta: 'Principal',
          component: () => import(/* webpackChunkName: "about" */ './views/Principal.vue')
        },
        {
          path: 'usuario',
          name: 'Usuarios',
          meta: 'Usuarios',
          component: () => import(/* webpackChunkName: "about" */ './views/usuario/Usuarios.vue')
        },

        {
          path: 'cartao',
          name: 'Cartao',
          meta: 'Cartao',
          component: () => import(/* webpackChunkName: "about" */ './views/cartao/Cartao.vue')
        },
        {
          path: 'apuracao',
          name: 'Apuracao',
          meta: 'Apuracao',
          component: () => import(/* webpackChunkName: "about" */ './views/apuracao/Apuracao.vue')
        },
      ]
    },
    // 404
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "about" */ './views/404.vue')
    }
  ]
})
