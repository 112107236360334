/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class Cartao {

  constructor () {
    this.cartao_id = uuidV4();
    this.nome = ''
    this.num1 = ''
    this.num2 = ''
    this.num3 = ''
    this.num4 = ''
    this.num5 = ''
    this.num6 = ''
    this.num7 = ''
    this.num8 = ''
    this.num9 = ''
    this.num10 = ''
    this.num11 = ''
    this.num12 = ''
    this.num13 = ''
    this.num14 = ''
    this.num15 = ''
  }
}
